<template>
  <div>

    <div v-if="!everythingReady" class="d-flex justify-content-center mb-5">
      <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
    
    </div> 
  <div class="account" v-show="everythingReady">
    <!--CRow>
      <CCol sm>
        <payment-method @load-ready="loadComponent"></payment-method>
      </CCol>
    </CRow-->

    <CRow>
      <CCol sm>
            <CCard>
                <CCardHeader>
                    <h4>{{ $t("my_settings") }}</h4>
                </CCardHeader>
                <CCardBody>
                  <div class="ligneSetting">
                    <div>{{ $t("update_password") }}</div>
                    <div><a :href="url+'/password/reset'" target="_blank">{{ $t("use_recovered") }} <span class="material-symbols-outlined outlink">open_in_new
</span></a></div>
                  </div>
                  
                  <div class="ligneSetting withTopLine">
                    <div>{{ $t("langue") }}</div>
                    <div>
                      <select v-model="lang" class="form-select" @change="langChange">
                                <option value="fr">Francais</option>
                                <option value="en">English</option>
                                <option value="es">Español</option>
                                <option value="de">Deutsch</option>
                                <option value="it">Italien</option>
                            </select>
                    </div>
                  </div>

                  <div v-if="url_stripe" class="ligneSetting withTopLine">
                    <div>{{ $t("gestion_paiement") }}</div>
                    <div>

                      <a :href="url_stripe" target="_blank">
                        {{ $t("stripe_link") }} 
                        <span class="material-symbols-outlined outlink">open_in_new</span>
                      </a>
                    </div>
                  </div>

                  <!--div class="ligneSetting withTopLine">
                    <div>{{ $t("espace_stockage") }}</div>
                    <div style="display: flex; gap: 5px; align-items: center;">
                      <div>{{ percent_storage }}%</div>
                      <CProgress class="mn">
                        <CProgressBar :value="percent_storage"></CProgressBar>
                      </CProgress>
                    </div>
                  </div-->
                    <!--ul>
                        <li>Pour changer votre mot de passe, utiliser la recuperation du mot de passe <a :href="url+'/password/reset'" target="_blank">ici <span class="material-symbols-outlined outlink">
open_in_new
</span></a> </li>
                        <li>Langue : 

                            <select v-model="lang" class="form-select" @change="langChange">
                                <option value="fr">Francais</option>
                                <option value="en">English</option>
                                <option value="es">Español</option>
                            </select>
                        </li>
                        
                    </ul-->
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
    
  

    

    <!--router-link to="/dashboard/about">About</router-link-->
  </div>
</div>
</template>

<script>
//import PaymentMethod from "../components/account/PaymentMethod.vue";
//import { CProgress } from '@coreui/vue'

export default {
  name: "Setting",
  components: {
    //PaymentMethod,
   // CProgress 
  },
  data() {
    return {
      errors: {},
      loading: false,
      /*session_stripe_id: null,
      abos: null,
      errorCancel: null,
      abo_user: {},
      orders: null,*/
      lang: this.$i18n.locale,
      url: process.env.VUE_APP_API_URL,
      everythingReady: false,
      total_media: 0,
      max_storage: 0,
      percent_storage: 0,
      url_stripe: null
    };
  },
  created(){
      console.log()
      const self = this;
      if(this.$store.state.user.abos[0] != 'free')
      {
        window.axios
          .get("/api/link_stripe_gate")
          .then((rep) => {
            self.url_stripe = rep.data.link
            self.everythingReady = true
          });
      }
      else
      self.everythingReady = true
      /*
      window.axios
        .get("/api/user/storage")
        .then((rep) => {
          self.total_media = rep.data.total_media
          self.max_storage = rep.data.max
          self.percent_storage = Math.ceil((self.total_media/self.max_storage)*100)
        });*/
  },
  
  methods: {
    langChange(){
      this.$i18n.locale = this.lang
      localStorage.setItem('lang', this.lang)
    },
    loadComponent(){
      this.everythingReady = true;
      console.log('xxxxxxx', this.everythingReady)
      this.$forceUpdate();
    }

   
  },
  mounted: function () {
   /* if (this.$store.state.user.abos[0])
      // si authentifié
      this.abo_user = this.$store.state.user.abos[0];*/
      
  },
};
</script>

<style scoped>
h6,
h4 {
  color: #f45a2a;
  font-weight: bold;
}

.block-prix {
  display: flex;
  align-items: center;
}

.prix {
  font-size: 42px;
  font-weight: bold;
  margin-right: 5px;
}

li {
  font-size: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.col-flex {
  display: flex;
}
.text-blue {
  padding: 7px;
}

.tabOrders{
  width: 100%;
}

td{
  padding-bottom:5px;
  padding-top: 5px;
}

ul.listAbo{
  list-style: none;
    padding-left: 0;
}

ul.listAbo li{
  display: flex;
    align-items: start;
    gap: 5px;
    padding-bottom: 12px;
}

.green{
  color: rgb(1, 211, 1);
}

.red{
  color: red;
}

b{
  color:#f45a2a;
}

.mediumAbo{
  background-color: #f45a2a;
  color:white;
}

.mediumAbo h6{
  color: white;
  display: flex;
  align-items: center;
  gap:5px;
}

.card-header h6{
  height: 24px;
}

.outlink{
  font-size: 18px;
}

.mn{
  min-width: 200px;
}

.ligneSetting{
  display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    
}

.withTopLine{
  padding-top: 20px;
  border-top: 1px solid #d8dbe0;
}

</style>
